import * as React from "react"

const ServicesPage = () => {
  return (
    <h1>Services</h1>
  )
}

export default ServicesPage

export const Head = () => <title>TMOY || Services</title>